import React from 'react';

const Footer = () => {
  return (
    <div style={{background:"black", height:"50px", marginTop:"0px"}}>
    <footer>
      <div style={{textAlign:"center", color:"white", margin:"0 auto"}}>
        <p>© 2023 Copyright: TravelLanka.lk</p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;